import { ChatsActionTypes } from "./types";

// common success
export const chatsApiResponseSuccess = (actionType: string, data: any) => ({
  type: ChatsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const chatsApiResponseError = (actionType: string, error: string) => ({
  type: ChatsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getFavourites = () => ({
  type: ChatsActionTypes.GET_FAVOURITES,
});

export const getDirectMessages = () => ({
  type: ChatsActionTypes.GET_DIRECT_MESSAGES,
});

export const getChannels = () => ({
  type: ChatsActionTypes.GET_CHANNELS,
});

export const addContacts = (contacts: Array<string | number>) => ({
  type: ChatsActionTypes.ADD_CONTACTS,
  payload: contacts,
});

export interface CreateChannelPostData {
  id: any;
  name: string;
  members: Array<string | number>;
  description?: string;
}
export const createChannel = (channelData: CreateChannelPostData) => ({
  type: ChatsActionTypes.CREATE_CHANNEL,
  payload: channelData,
});

export const changeSelectedChat = (selectedChat: string | number | null) => ({
  type: ChatsActionTypes.CHANGE_SELECTED_CHAT,
  payload: selectedChat,
});

export const changeIsLoadConversation = (value: boolean) => ({
  type: ChatsActionTypes.CHANGE_IS_LOAD_CONVERSATION,
  payload: value,
});

export const changeReplyMsgSelected = (messageId: string | number | null) => ({
  type: ChatsActionTypes.CHANGE_REPLY_MSG_SELECTED,
  payload: messageId,
});

export const addTagGroupSuccess = (value: boolean) => ({
  type: ChatsActionTypes.ADD_TAG_GROUP_SUCCESS,
  payload: value,
});

export const changeChannelSelected = (channelSelected: string | number | null) => ({
  type: ChatsActionTypes.CHANGE_CHANNEL_SELECTED,
  payload: channelSelected,
});

export const changeConversationSelected = (conversationSelected: any) => ({
  type: ChatsActionTypes.CHANGE_CONVERSATION_SELECTED,
  payload: conversationSelected,
});

export const changeSelectedConversation = (obj: any) => ({
  type: ChatsActionTypes.CHANGE_SELECTED_CONVERSATION,
  payload: obj,
});

export const changeGroupInfo = (obj: any) => ({
  type: ChatsActionTypes.CHANGE_GROUP_INFO,
  payload: obj,
});

export const changePageInfo = (obj: any) => ({
  type: ChatsActionTypes.CHANGE_PAGE_INFO,
  payload: obj,
});

export const getChatUserDetails = (selectedChat: string | number | null) => ({
  type: ChatsActionTypes.GET_CHAT_USER_DETAILS,
  payload: selectedChat,
});

export const getChatUserConversations = (
  selectedChat: string | number | null
) => ({
  type: ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
  payload: selectedChat,
});

export const getMessagesHistories = (
  data: { id: string | number; page: number; limit: number }
) => ({
  type: ChatsActionTypes.GET_MESSAGES_HISTORIES,
  payload: data,
});
export const updateTagConversation = (
  data: { conversationId: string; tags: any }
) => ({
  type: ChatsActionTypes.UPDATE_TAG_CONVERSATION,
  payload: data,
});
export const initMessagesHistories = (
  data: {}
) => ({
  type: ChatsActionTypes.INIT_MESSAGES_HISTORIES,
  payload: {},
});

export const getConversationList = (
  data: any
) => ({
  type: ChatsActionTypes.GET_CONVERSATION_LIST,
  payload: data,
});

export const updateConversationList = (
  data: any
) => ({
  type: ChatsActionTypes.UPDATE_CONVERSATION_LIST,
  payload: data,
});

export const updateTagsInConversation = (
  data: any
) => ({
  type: ChatsActionTypes.UPDATE_TAGS_IN_CONVERSATION,
  payload: data,
});

export const changeConversationFilterState = (obj: any) => ({
  type: ChatsActionTypes.CHANGE_CONVERSATION_FILTER_STATE,
  payload: obj,
});



export const updateConversationSelect = (
  data: any
) => ({
  type: ChatsActionTypes.UPDATE_CONVERSATION_SELECT,
  payload: data,
});

export const toggleUserDetailsTab = (value: boolean) => ({
  type: ChatsActionTypes.TOGGLE_USER_DETAILS_TAB,
  payload: value,
});

export const onSendMessage = (data: any) => ({
  type: ChatsActionTypes.ON_SEND_MESSAGE,
  payload: data,
});

export const onSendMultiMsg = (data: any) => ({
  type: ChatsActionTypes.ON_SEND_MULTI_MESSAGE,
  payload: data,
});

export const onSendOneMessWithFile = (data: any) => ({
  type: ChatsActionTypes.ON_SEND_ONE_MESSAGE,
  payload: data,
});

export const onDeleteMessage = (data: any) => ({
  type: ChatsActionTypes.ON_DELETE_MESSAGE,
  payload: data,
});

export const receiveMessage = (id: number | string) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE,
  payload: id,
});

export const readMessage = (id: number | string) => ({
  type: ChatsActionTypes.READ_MESSAGE,
  payload: id,
});

export const receiveMessageFromUser = (id: number | string) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
  payload: id,
});

export const deleteMessage = (
  userId: number | string,
  messageId: number | string
) => ({
  type: ChatsActionTypes.DELETE_MESSAGE,
  payload: { userId, messageId },
});

export const forwardMessage = (data: object) => ({
  type: ChatsActionTypes.FORWARD_MESSAGE,
  payload: data,
});

export const deleteUserMessages = (userId: number | string) => ({
  type: ChatsActionTypes.DELETE_USER_MESSAGES,
  payload: userId,
});

export const getChannelDetails = (id: number | string) => ({
  type: ChatsActionTypes.GET_CHANNEL_DETAILS,
  payload: id,
});

export const toggleFavouriteContact = (id: number | string) => ({
  type: ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
  payload: id,
});

export const getArchiveContact = () => ({
  type: ChatsActionTypes.GET_ARCHIVE_CONTACT,
});

export const toggleArchiveContact = (id: number | string) => ({
  type: ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
  payload: id,
});

export const readConversation = (id: number | string) => ({
  type: ChatsActionTypes.READ_CONVERSATION,
  payload: id,
});

export const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => ({
  type: ChatsActionTypes.DELETE_IMAGE,
  payload: { userId, messageId, imageId },
});

export const uploadListImageDraft = (listImage: any) => ({
  type: ChatsActionTypes.UPDATE_LIST_IMAGE_DRAFT,
  payload: listImage,
});

export const updateTagList = (tagList: any) => ({
  type: ChatsActionTypes.UPDATE_TAG_LIST,
  payload: tagList,
});

export const refreshConversation = (refreshNumber: any) => ({
  type: ChatsActionTypes.REFRESH_CONVERSATION_LIST,
  payload: refreshNumber,
});

export const onReadedMess = (payload: any) => ({
  type: ChatsActionTypes.MESSAGE_USER_READED,
  payload: payload,
});

export const onReceiveReaction = (payload: any) => ({
  type: ChatsActionTypes.RECEIVE_REACTION,
  payload: payload,
});

export const updateReplyMessage = (replyMessage: any) => ({
  type: ChatsActionTypes.UPDATE_REPLY_MESSAGE,
  payload: replyMessage || {},
});


export const onSendMessageByExtension = (data: any) => ({
  type: ChatsActionTypes.ON_SEND_MESSAGE_BY_EXTENSION,
  payload: data || {},
});

export const onSendMessageByExtensionFail = (data: any) => ({
  type: ChatsActionTypes.ON_SEND_MESSAGE_BY_EXTENSION_FAIL,
  payload: data || {},
});

export const onHandleStatusExtension = (data: any) => ({
  type: ChatsActionTypes.ON_HANDLE_STATUS_EXTENSION,
  payload: data || {},
});

export const onHandleStatusExtensionTranslate = (data: any) => ({
  type: ChatsActionTypes.ON_HANDLE_STATUS_EXTENSION_TRANSLATE,
  payload: data || {},
});


export const onSearch = (data: any) => ({
  type: ChatsActionTypes.ONSEARCH_CONVERSATION,
  payload: data,
});

export const onTranslationMessageId = (data: any) => ({
  type: ChatsActionTypes.ONTRANSLATIONMESSAGEID,
  payload: data,
});

export const onUpdateTranslationSuccess = (data: any) => ({
  type: ChatsActionTypes.ON_UPDATE_TRANSLATION_SUCCESS,
  payload: data,
});

export const onChangeTextTranslateInput = (data: any) => ({
  type: ChatsActionTypes.ON_CHANGE_TEXT_TRANSLATE_INPUT,
  payload: data,
});





