import React, { lazy, Suspense } from 'react';
import LoadingIcon from '../components/Loading'; // Đường dẫn tới component LoadingIcon của bạn

const Root = lazy(() => import("../pages/Root/index"));
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const Dashboard = lazy(() => import("../pages/Dashboard/index"));
const StarterPage = lazy(() => import("../pages/StarterPage/index"));
const ConversationList = lazy(() => import("../pages/ConversationList/index"));
const Tags = lazy(() => import("../pages/Tags/index"));
const LayoutStatistic = lazy(() => import("../pages/Statistic/index"));
const Overview = lazy(() => import("../pages/Statistic/Overview"));
const Inbox = lazy(() => import("../pages/Statistic/Interact"));
const OrderedList = lazy(() => import("../pages/OrderedList"));
const LayoutSetting = lazy(() => import("../pages/Setting/index"));
const QuickReplies = lazy(() => import("../pages/QuickReplies/index"));
const SyncMessage = lazy(() => import("../pages/SyncMessage"));
const UserManagement = lazy(() => import("../pages/UserManagement"));
const GroupManagement = lazy(() => import("../pages/GroupManagement"));
const AssignPage = lazy(() => import("../pages/AssignPage"));

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-login", component: <Suspense fallback={<LoadingIcon />}><Login /></Suspense> },
  { path: "/logout", component: <Suspense fallback={<LoadingIcon />}><Logout /></Suspense> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/pages-starter", component: <Suspense fallback={<LoadingIcon />}><StarterPage /></Suspense> },
  { path: "/dashboard", component: <Suspense fallback={<LoadingIcon />}><Dashboard /></Suspense> },
  { path: "/list-order", component: <Suspense fallback={<LoadingIcon />}><Dashboard /></Suspense> },
  { path: "/conversation-list", component: <Suspense fallback={<LoadingIcon />}><ConversationList /></Suspense> },
  { path: "/ordered-list", component: <Suspense fallback={<LoadingIcon />}><OrderedList /></Suspense> },
  { path: "/user-management", component: <Suspense fallback={<LoadingIcon />}><UserManagement /></Suspense> },
  { path: "/group-management", component: <Suspense fallback={<LoadingIcon />}><GroupManagement /></Suspense> },
  { path: "/assign-page", component: <Suspense fallback={<LoadingIcon />}><AssignPage /></Suspense> },
  {
    path: "/setting/tags",
    component: <Suspense fallback={<LoadingIcon />}><LayoutSetting><Tags /></LayoutSetting></Suspense>
  },
  {
    path: "/setting/quick-replies",
    component: <Suspense fallback={<LoadingIcon />}><LayoutSetting><QuickReplies /></LayoutSetting></Suspense>
  },
  {
    path: "/setting/sync-message",
    component: <Suspense fallback={<LoadingIcon />}><LayoutSetting><SyncMessage /></LayoutSetting></Suspense>
  },
  {
    path: "/statistic/overview",
    component: <Suspense fallback={<LoadingIcon />}><LayoutStatistic><Overview /></LayoutStatistic></Suspense>
  },
  {
    path: "/statistic/inbox",
    component: <Suspense fallback={<LoadingIcon />}><LayoutStatistic><Inbox /></LayoutStatistic></Suspense>
  },
  { path: "/", component: <Suspense fallback={<LoadingIcon />}><Root /></Suspense> },
];

export { publicRoutes, privateRoutes };